import '../Shared/bootstrap'
import '../../css/app.css'

import * as Sentry from '@sentry/vue'

import dayjs from 'dayjs'
import dayjsCustomParseFormatPlugin from 'dayjs/plugin/customParseFormat'
import dayjsDurationPlugin from 'dayjs/plugin/duration'

import { createApp, h } from 'vue'
import { createInertiaApp } from '@inertiajs/vue3'
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers'
import { ZiggyVue } from '../../../vendor/tightenco/ziggy/dist/vue.m'
import { createPinia } from 'pinia'
import Vue3Toasity from 'vue3-toastify'
import 'vue3-toastify/dist/index.css'

import { can } from '@/App/Helpers/PermissionHelper.js'

dayjs.extend(dayjsCustomParseFormatPlugin).extend(dayjsDurationPlugin)

const appName = window.document.getElementsByTagName('title')[0]?.innerText || 'TravelEssex'

createInertiaApp({
  title: (title) => `${title} | ${appName}`,
  resolve: (name) => resolvePageComponent(`./Pages/${name}.vue`, import.meta.glob('./Pages/**/*.vue')),
  setup({ el, App, props, plugin }) {
    const app = createApp({ render: () => h(App, props) })

    app.config.globalProperties.$date = dayjs

    app.config.globalProperties.$pluralize = (amount, singular, plural) =>
      amount > 1 || amount === 0 ? plural : singular

    app.config.globalProperties.$can = can

    if (import.meta.env.VITE_APP_ENV != 'local' && import.meta.env.VITE_APP_ENV != 'testing') {
      Sentry.init({
        app,
        dsn: import.meta.env.VITE_SENTRY_LARAVEL_DSN,
        environment: import.meta.env.VITE_APP_ENV,
      })
    }

    app
      .use(plugin)
      .use(createPinia())
      .use(ZiggyVue)
      .use(dayjs)
      .use(Vue3Toasity, {
        autoClose: 3000,
      })
      .mount(el)

    return app
  },
  progress: {
    color: '#E97135',
  },
})
