import { usePage } from '@inertiajs/vue3'

/**
 * Check if user has (can) permission.
 *
 * @param {string|array} permissions The permission to check.
 * @param {any} boolean Check if only one or all permissions must pass the truth test (some vs every).
 * @return {boolean}
 */
export const can = (permissions, any = true) => {
  if (!Array.isArray(permissions)) {
    permissions = [permissions]
  }

  const accessiblePermissions = usePage().props.user.permissions

  return accessiblePermissions[any ? 'some' : 'every']((permission) => permissions.includes(permission))
}
